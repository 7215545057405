<template>
  <v-container>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title
            class="headline primary"
            primary-title
        >
          {{this.$t('dialog.dataFound.headline')}}
        </v-card-title>

        <v-card-text class="mt-3">
          {{this.$t('dialog.dataFound.body')}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="clearRecoverData()"
              color="error"
              text
          >
            {{this.$t('button.delete')}}
          </v-btn>
          <v-btn
              @click="recoverData()"
              color="primary"
          >
            {{this.$t('button.restore')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-form
            :lazy-validation="lazy"
            class="form"
            ref="form"
            v-model="valid"
        >
          <v-container style="min-width: 25vw" v-on:input="save()">
            <v-row>
              <v-col cols="6" v-if="hideNumbers(claimNumber)">
                <v-text-field
                    :label="inputLabels.policyNumber"
                    :rules="policyNumberRules"
                    type="number"
                    :counter="maxCounterPolicyNumber"
                    v-model="policyNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="hideNumbers(policyNumber)">
                <v-text-field
                    :label="inputLabels.claimNumber"
                    :rules="claimNumberRules"
                    type="number"
                    v-model="claimNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
            <v-text-field
                label="Name der Firma/Person"
                required
                v-model="name"
            ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="email"
                    label="E-Mail des Kunden"
                    :rules="emailRules"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                    v-model="phone"
                    label="Telefonnummer des Kunden"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
            <v-textarea
                :counter="500"
                label="Kommentar"
                auto-grow
                required
                v-model="issue"
            ></v-textarea>
              </v-col>
              <v-col cols="12">
            <v-autocomplete
                :items="creators"
                label="Anwalt *"
                placeholder="Anwalte mit Online Beratung Berechtigung"
                :search-input.sync="searchCreator"
                clearable
                color="primary"
                hide-no-data
                hide-selected
                item-text="username"
                item-value="username"
                return-object
                :rules="creatorRules"
                v-model="creator"
            ></v-autocomplete>
              </v-col>
              <v-col>
                <v-file-input
                    v-model="pictures"
                    label="Dokument hochzuladen"
                    :show-size="1000"
                    accept="application/pdf,image/*"
                    color="secondary"
                    :rules="pictureRules"
                    multiple
                    counter
                    required
                    prepend-icon="mdi-file-document"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 20"
                        color="accent"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 20"
                        class="overline grey--text text--darken-3 mx-2"
                    >
          +{{ pictures.length - 20 }} File(s)
        </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>

          <v-row
              class="mb-10"
              justify="end"
              md="4"
          >
            <v-btn
                @click="$router.back()"
                class="mr-4"
                color="error"
            >
              {{this.$t('button.cancel')}}
            </v-btn>

            <v-btn
                :disabled="blockButtonWhileRequestAndForm()"
                @click="validate"
                class="mr-4"
                color="success"
            >
              {{this.$t('button.sent')}}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
          </v-card>
  </v-container>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAragURL, getLawyersURL, getStagLegalUploadURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import dateFormatter from "@/utils/dateFormatter";
import { PdfService } from "@/utils/imgToPdf";

export default {
  name: "AragOnlineBeratung",
  data: () => ({
    creator: undefined,
    creators: [],
    searchCreator: null,
    valid: true,
    isLoading: false,
    phone: '+49',
    pictures: [],
    uploadLimitHit: [],
    sessionOutdated: [],
    email: '',
    notes: '',
    policyNumber: '',
    claimNumber: '',
    checkNumbers: ["1100","1101","1000","1001"],
    name: '',
    activityType: {id: "01", name: "Straf/Owi Straßenverkehr"},
    issue: '',
    free: false,
    sms: true,
    done: false,
    date: new Date().toISOString().substr(0, 10),
    dateMenu: false,
    consultationDate: new Date().toISOString().substr(0, 10),
    consultationDateMenu: false,
    lazy: false,
    dialog: false,
    maxCounterPolicyNumber: 8,
  }),
  methods: {
    // disable sent button so prevent multiple entries
    blockButtonWhileRequestAndForm() {
      if (this.valid === false) {
        return true
      }
      return this.isLoading === true;
    },
    ownDecode(url){
      let temp = encodeURI(url);
      temp = temp.replaceAll(":", "%3A");
      temp = temp.replaceAll("/", "%2F");
      temp = temp.replaceAll("?", "%3F");
      temp = temp.replaceAll("#", "%23");
      temp = temp.replaceAll("[", "%5B");
      temp = temp.replaceAll("]", "%5D");
      temp = temp.replaceAll("@", "%40");
      temp = temp.replaceAll("$", "%24");
      temp = temp.replaceAll("&", "%26");
      temp = temp.replaceAll("+", "%2B");
      temp = temp.replaceAll(",", "%2C");
      temp = temp.replaceAll(";", "%3B");
      temp = temp.replaceAll("=", "%3D");
      return temp
    },
    async sendData() {
      // set Loading true
      this.isLoading = true;

      // init Request Data
      const formData = {
        "aragDataType": 'OnlineBeratung',
        "customerMailAddress": this.email,
        "claimDate": this.date,
        "customerPhoneNumber": this.phone,
        "claimNumber": this.claimNumber,
        "contractNumber": this.policyNumber,
        "comment": this.issue,
        "consultationDate": this.consultationDate,
        "customerName": this.name,
      };
      // console.log("data", formData)
      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .post(
              getAragURL(false) + "/" + this.creator.username,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.clearRecoverData();
              showAlert(i18n.t('success.form'), "success");
              this.upload(this.ownDecode(response.headers["x-key"]))
              this.$router.push("/")
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "ARAG-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async upload(key){
      this.isLoading = true
      let formData = [];
      const documents = this.pictures.filter(img => img.type.split("/")[0] !== "image")
      const pictures = this.pictures.filter(img => img.type.split("/")[0] === "image")
      documents.forEach(doc => {
        let temp = new FormData()
        temp.append("file",doc)
        formData.push(temp)
      })
      if(pictures.length > 0){
        const service = new PdfService();
        const pdf = await service.generatePdf("GeneratedPDF", pictures)
        //pdf.open()
        pdf.getBlob(blob => {
          let temp = new FormData()
          temp.append("file",blob, "ConvertedImages.pdf")
          formData.push(temp)
          formData.forEach((fD, index) => {
            this.$http.post(
                getStagLegalUploadURL(key, "Arag"),
                fD
            ).then(() => {
              if (index === formData.length-1){
                this.pictures = [];
                this.done = true;
                //ALERT
                showAlert("Vielen Dank", "success")
                this.isLoading = false;
              }
            }).catch(error =>{
                  if (429 === error.response.status){
                    index = formData.length-1;
                    this.uploadLimitHit = true
                  }
                  if (412 === error.response.status){
                    index = formData.length-1;
                    this.sessionOutdated = true
                  }
                }
            )
          })
        })
      }
      if(pictures.length === 0){
        formData.forEach((fD, index) => {
          // console.log(formData)
          this.$http.post(
              getStagLegalUploadURL(key, "Arag"),
              fD
          ).then(() => {
            if (index === formData.length-1){
              this.pictures = [];
              this.done = true;
              //ALERT
              showAlert("Vielen Dank", "success")
              this.isLoading = false;
            }
          }).catch(error =>{
                if (429 === error.response.status){
                  index = formData.length-1;
                  this.uploadLimitHit = true
                }
                if (412 === error.response.status){
                  index = formData.length-1;
                  this.sessionOutdated = true
                }
              }
          )
        })
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.sendData();
      }

    },
    save() {
      let store = {
        "policyNumber": this.policyNumber,
        "claimNumber": this.claimNumber,
        "processSelf": this.processSelf,
        "documentcheck": this.documentcheck,
        "notesDocument": this.notes,
        "customerPhoneNumber": this.phone,
        "email": this.email,
        "sms": this.sms,
        "name": this.name,
        "activityType": this.activityType,
        "date": this.date,
        "issue": this.issue,
        "free": this.free,
        "consultationDate": this.consultationDate
      }
      localStorage.setItem('aragOnlineBeratung', JSON.stringify(store));

    },
    saveDate() {
      this.save();
      this.dateMenu = false
    },
    saveConsultationDate() {
      this.save();
      this.consultationDateMenu = false;
    },
    // Clear localStorage
    clearRecoverData() {
      localStorage.setItem('aragOnlineBeratung', "")
      this.dialog = false;
    },
    recoverData() {
      let recoverableData = localStorage.getItem('aragOnlineBeratung');
      recoverableData = JSON.parse(recoverableData);
      this.policyNumber = recoverableData.policyNumber;
      this.processSelf = recoverableData.processSelf;
      this.documentcheck = recoverableData.documentcheck;
      this.claimNumber = recoverableData.claimNumber;
      this.name = recoverableData.name;
      this.email = recoverableData.email;
      this.phone = recoverableData.customerPhoneNumber;
      this.sms = recoverableData.sms;
      this.notes = recoverableData.notesDocument;
      this.activityType = recoverableData.activityType;
      this.date = recoverableData.date;
      this.issue = recoverableData.issue;
      this.free = recoverableData.free;
      this.consultationDate = recoverableData.consultationDate;
      this.dialog = false;
    },
    //  if there is data to recover
    checkRecoverData() {
      let recoverableData = localStorage.getItem('aragOnlineBeratung');
      if (recoverableData) {
        this.dialog = true;
      }
    },
    //Hide policyNumber/claimNumber
    hideNumbers(otherNumber) {
      if (this.free) {
        return false;
      }
      if (otherNumber === '') {
        return true;
      }
    },

    // format Date
    formatDate(unformedDate){
      return dateFormatter(unformedDate);
    },

    async getAllCreators() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              // console.log(response.data)
              //ONLY SHOW Lawyers with permission
              this.creators = response.data.filter((creator) => { return creator.aragPermissions.OnlineBeratung})
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response){
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }} else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },
  },
  computed: {
    inputLables() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.inputField')));
    },
    pictureRules() {
      return [
        files => !files || !files.some(file => file.size > 100_663_296) || 'Eine Datei darf nicht größer als 100mb sein.',
        v => v.length !== 0 || "Bitte fügen Sie Ihre Dokumente hinzu."
      ]
    },
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    inputLabels() {
      return JSON.parse(JSON.stringify(this.$t('insurance.Arag.inputField')));
    },
    emailRules() {
      return [
        v => (v.length > 0 ? /^\S+@\S+\.\S+$/.test(v) : true) || this.errorMessage["validEmail"],
      ]
    },
    notesRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => (v && v.length <= 300) || this.errorMessage["maxLength300"],
      ]
    },
    policyNumberRules() {
      return [
        v => v.length > 0 ? /^\d+$/.test(v) || this.errorMessage["onlyNumbers"] : true,
        v => v !== "00000000"
      ]
    },
    claimNumberRules() {
      return [
        v => /^\d+$/.test(v) || this.errorMessage["onlyNumbers"],
        v => (v.substring(0,1).includes("3") && v.length === 14)|| this.errorMessage["invalidClaimNumber"],
        v => !!v || this.errorMessage.required,
      ]
    },
    creatorRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
  },
  mounted() {
    this.checkRecoverData();
  },
  watch: {
    policyNumber: {
      handler() {
        this.maxCounterPolicyNumber = (-1 !== this.checkNumbers.findIndex(element => {
          if(this.policyNumber.length > 3){
            if (element.includes(this.policyNumber.substring(0,4))) {
              return true;
            }
          }
        }) ? 14 : 8);
      }
    },
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    },
  }
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
